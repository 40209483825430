import React, { useMemo } from 'react';
import { StageListItem, useStageList } from '@hooks/workspace/stages/useStageList';
import { ProjectStageType, RecordType } from '@types';
import { Loader } from '@kit/ui/Loader';
import { isCustomerPortalEnabled } from '@state/selectors';
import { useAppSelector } from '@hooks/store';
import { StagesTable } from './StagesTable';
import { Description, Header, Title, Content } from './styled';

const REQUEST_STAGE_TYPES_ORDER: ProjectStageType[] = [
  ProjectStageType.new,
  ProjectStageType.selling,
  ProjectStageType.won,
  ProjectStageType.lost
];
const PROJECT_STAGE_TYPES_ORDER: ProjectStageType[] = [
  ProjectStageType.initiation,
  ProjectStageType.production,
  ProjectStageType.complete
];

const sortStages = (stages: StageListItem[], order: ProjectStageType[]) => {
  return stages.sort((a, b) => {
    if (a.type === b.type) {
      return a.name.localeCompare(b.name);
    }

    return order.indexOf(a.type as ProjectStageType) - order.indexOf(b.type as ProjectStageType);
  });
};

export const List = () => {
  const { data: stages = [], isLoading } = useStageList();

  const isPortalEnabled = useAppSelector(isCustomerPortalEnabled);

  const projectStages = useMemo(() => {
    return sortStages(
      stages.filter((stage) => stage.scope === 'PROJECT'),
      PROJECT_STAGE_TYPES_ORDER
    );
  }, [stages]);

  const requestStages = useMemo(() => {
    return sortStages(
      stages.filter((stage) => stage.scope === 'DEAL'),
      REQUEST_STAGE_TYPES_ORDER
    );
  }, [stages]);

  return (
    <div>
      <Header>
        <Title>Stages</Title>
        <Description>Configure stages for Requests and Projects.</Description>
      </Header>

      {isLoading && (
        <Content>
          <Loader />
        </Content>
      )}

      {!isLoading && (
        <Content>
          <StagesTable
            title="Request Stages"
            addButtonText="Request Stage"
            stages={requestStages}
            recordType={RecordType.DEAL}
          />

          <StagesTable
            title="Project Stages"
            addButtonText="Project Stage"
            isDescriptionShown={isPortalEnabled}
            stages={projectStages}
            recordType={RecordType.PROJECT}
          />
        </Content>
      )}
    </div>
  );
};
